import React, { FC, useEffect, useState } from 'react'
import {
  HostResponse,
  updateHost,
  CredentialsResponse,
  getCredentials,
  ApiResponse,
  IUpdateHost
} from '@utils/api'
import useApi, { TUseApiHook } from '@hooks/useApi'
import { Box, Grid } from '@mui/material'
import { Card, Select, Button, TagField } from '@components'
import { Skeleton } from '@mui/material'
import useForm from '@hooks/useForm'
import { getJumpHosts, JumpHostsResponse } from '@utils/api/jumphosts'
import TextFieldAutocomplete, {
  TTextFieldAutocompleteProps
} from '@components/TextFieldAutocomplete'
import useFeatures from '@hooks/useFeatures'

type THostDetailSettings = {
  api: TUseApiHook<HostResponse>
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>
  host_id: string
}

const HostDetailSettings: FC<THostDetailSettings> = ({ api, host_id = '' }) => {
  const { response, makeRequest, enqueueSnackbar } = api
  const [jumpHostOptions, setJumpHostOptions] = useState<
    TTextFieldAutocompleteProps['options']
  >([])
  const { canUseFeature } = useFeatures()

  const isAuthenticated = response?.authentication_status == 'ok' || false

  const credentialsForm = useForm<{ credentials_id: string }>({
    initialValues: {
      credentials_id: response?.credentials_id || ''
    },
    initialValidationRules: {
      credentials_id: (value, _values) => !!value && value !== ''
    }
  })

  const jumpHostForm = useForm<{ jump_hosts: string[] }>({
    initialValues: {
      jump_hosts: response?.jump_hosts || []
    },
    initialValidationRules: {}
  })

  const credentialsApi = useApi<CredentialsResponse>({
    apiMethod: getCredentials
  })

  const jumpHostsApi = useApi<JumpHostsResponse>({
    apiMethod: getJumpHosts
  })

  const updateApi = useApi<ApiResponse<string>>({
    apiMethod: updateHost,
    requestOnMount: false
  })

  const handleUpdate = (payload: IUpdateHost) => {
    if (response) {
      updateApi
        .makeRequest({
          ...payload
        })
        .then(({ status }) => {
          if (status >= 200 && status < 300) {
            enqueueSnackbar('Host updated', {
              variant: 'success'
            })

            setTimeout(() => {
              makeRequest(response.host_id)
            }, 500)
          }
        })
    }
  }

  const handleTagUpdate = () => {
    if (api.response) {
      updateApi
        .makeRequest({
          host_id,
          tags: tagForm.values.host_tags
        })
        .then(({ status }) => {
          if (status >= 200 && status < 300) {
            api.enqueueSnackbar('Host tags updated', {
              variant: 'success'
            })

            api.response && api.makeRequest(api.response.host_id)
          }
        })
    }
  }

  const canTag = canUseFeature('host_tags')

  const tagForm = useForm<{ host_tags: string[] }>({
    initialValues: {
      host_tags: []
    }
  })

  useEffect(() => {
    if (response && response?.credentials_id) {
      credentialsForm.setFieldValue('credentials_id', response.credentials_id)
    }

    if (response && response?.jump_hosts) {
      jumpHostForm.setFieldValue('jump_hosts', response.jump_hosts)
    }

    if (api.response?.tags) {
      tagForm.setFieldValue('host_tags', api.response.tags)
    }
  }, [api.response])

  useEffect(() => {
    const { response } = jumpHostsApi
    if (response && response.data && Array.isArray(response.data)) {
      const jumpHosts = response.data.map(({ name }) => name)
      setJumpHostOptions(jumpHosts)
    }
  }, [jumpHostsApi.response])

  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item sm={12}>
        <Box mb={3}>
          <Card
            heading="Host Tags"
            style={{ height: 'auto' }}
            feature="host_tags"
            actions={
              <Box pt={2} textAlign="right">
                <Button
                  variant="contained"
                  feature="host_tags"
                  color="primary"
                  disabled={
                    JSON.stringify(tagForm.values.host_tags) ===
                    JSON.stringify(api.response?.tags)
                  }
                  onClick={() => handleTagUpdate()}
                >
                  Update
                </Button>
              </Box>
            }
          >
            <Box pt={3} px={2}>
              <TagField
                id="host_tags"
                label="Tags"
                disabled={!canTag}
                form={tagForm as any}
                helperText="Search and select one or more existing tags, or enter a new tag and press return key to add."
              />
            </Box>
          </Card>
        </Box>
        <Box mb={3}>
          <Card
            heading="Host Credential"
            color={!isAuthenticated ? 'warning' : 'default'}
            style={{ flexGrow: 0, height: 'auto' }}
            headingButton={
              <>
                <Button
                  size="small"
                  variant="outlined"
                  to={`/hosts/credentials/edit/${response?.credentials_id}`}
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  to={`/hosts/credentials/`}
                >
                  View Credentials
                </Button>
              </>
            }
            actions={
              <Button
                variant="contained"
                color="primary"
                disabled={
                  credentialsForm.values.credentials_id ===
                  api.response?.credentials_id
                }
                onClick={() =>
                  handleUpdate({
                    host_id: response?.host_id || '',
                    ...credentialsForm.values
                  })
                }
              >
                Switch
              </Button>
            }
          >
            <Box p={2}>
              {credentialsApi.loading ||
              !credentialsApi.response ||
              api.loading ? (
                <Skeleton height={64} />
              ) : (
                <Select
                  id="credentials_id"
                  label="Selected Credential"
                  form={credentialsForm as any}
                  options={
                    credentialsApi.response.data.map(({ credentials_id }) => ({
                      value: credentials_id,
                      label: credentials_id
                    })) || [
                      {
                        value: api.response?.credentials_id,
                        label: api.response?.credentials_id
                      }
                    ]
                  }
                />
              )}
            </Box>
          </Card>
        </Box>
        <Card
          heading="Jump Host"
          style={{ flexGrow: 0, height: 'auto' }}
          headingButton={
            <Button size="small" variant="outlined" to={`/hosts/jump-hosts/`}>
              View Jumphosts
            </Button>
          }
          feature="jump_hosts"
          actions={
            <Button
              feature="jump_hosts"
              variant="contained"
              color="primary"
              disabled={
                jumpHostForm.values.jump_hosts === api.response?.jump_hosts &&
                canUseFeature('jump_hosts')
              }
              onClick={() =>
                handleUpdate({
                  host_id: host_id,
                  ...jumpHostForm.values
                })
              }
            >
              Update
            </Button>
          }
        >
          <Box p={2}>
            {jumpHostsApi.response ? (
              <TextFieldAutocomplete
                required={false}
                id="jump_hosts"
                label="Jump Host"
                form={jumpHostForm as any}
                options={jumpHostOptions}
                disabled={!canUseFeature('jump_hosts')}
                helperText="Optional. Select in order of connection."
                freeSolo={false}
              />
            ) : (
              <Skeleton height={64} />
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}

export default HostDetailSettings
