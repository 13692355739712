React
import { DataGrid } from '@components'
import { Block, CheckCircleOutline } from '@mui/icons-material'
import { GridComponentProps } from '@mui/x-data-grid-pro'
import {
  deleteWhitelistSandfliesOnHost,
  DeleteWhitelistSandflyOnHost,
  getSandflies,
  HostResponse,
  TWhitelistSandflyOnHostConfig,
  whitelistSandfliesOnHost,
  whitelistSandflyOnHost
} from '@utils/api'
import useApi, { TUseApiHook } from '@utils/hooks/useApi'
import { tagRenderer, tooltipRenderer } from '@utils/tables'
import React, { FC } from 'react'

export const whitelistColumns: GridComponentProps['columns'] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 120,
    hide: true
  },
  {
    field: 'sandfly_whitelisted',
    headerName: 'Whitelisted',
    type: 'boolean',
    width: 130,
    editable: true
  },
  {
    field: 'active',
    headerName: 'Active',
    type: 'boolean',
    width: 125,
    hide: true
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 125,
    hide: true
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200
  },
  {
    field: 'description',
    headerName: 'Description',
    renderCell: tooltipRenderer
  },
  {
    field: 'tags',
    headerName: 'Tags',
    sortable: false,
    renderCell: tagRenderer,
    width: 200,
    flex: 1
  },
  {
    field: 'response',
    headerName: 'Response',
    width: 120,
    hide: true,
    valueGetter: (params) => {
      const {
        row: { type, options }
      } = params
      switch (type) {
        case 'process':
          const responses = options?.response?.[type] || {}
          const responseNames = Object.keys(responses)

          return (
            responseNames.find((responseType) => responses[responseType]) ||
            'Not set'
          )

        default:
          return 'n/a'
      }
    }
  }
]

type THostDetailPageWhitelist = {
  api: TUseApiHook<HostResponse>
  host_id: string
}

const HostDetailPageWhitelist: FC<THostDetailPageWhitelist> = ({
  api,
  host_id = ''
}) => {
  const { response, loading, error, makeRequest } = api

  const sandflyApi = useApi({
    apiMethod: getSandflies
  })

  const whitelistApi = useApi({
    apiMethod: whitelistSandflyOnHost,
    requestOnMount: false
  })

  const unwhitelistApi = useApi({
    apiMethod: DeleteWhitelistSandflyOnHost,
    requestOnMount: false
  })

  const whitelist =
    response && sandflyApi.response
      ? sandflyApi.response?.data.map((sandfly) => ({
          ...sandfly,
          sandfly_whitelisted:
            response && response.sandfly_whitelist.indexOf(sandfly.name) > -1
        }))
      : []

  return (
    <DataGrid
      config={{
        makeRequest: () => makeRequest(host_id),
        loading: loading || sandflyApi.loading,
        error: error || sandflyApi.error,
        columns: whitelistColumns,
        rows: whitelist,
        editCellHandler: async (params) => {
          const { id, value } = params

          const config: TWhitelistSandflyOnHostConfig = {
            host: host_id,
            sandfly: id as string
          }

          if (value) {
            await whitelistApi.makeRequest(config)
          } else {
            await unwhitelistApi.makeRequest(config)
          }

          await makeRequest(host_id)
        },
        initialSort: [
          {
            field: 'sandfly_whitelisted',
            sort: 'desc'
          },
          {
            field: 'name',
            sort: 'asc'
          }
        ],
        getRowClassName: ({ getValue, id }) =>
          `DataGrid__row--disabled-${getValue(id, 'active')}` +
          ` DataGrid__row--whitelisted-${getValue(id, 'sandfly_whitelisted')}`,
        bulkActions: [
          {
            text: 'Whitelist Selected',
            icon: <Block fontSize="small" />,
            action: async (selection: string[]) => {
              if (response) {
                await whitelistSandfliesOnHost({
                  ids: selection,
                  host: response.host_id
                })
              }
            }
          },
          {
            text: 'De-whitelist Selected',
            icon: <CheckCircleOutline fontSize="small" />,
            action: async (selection: string[]) => {
              response &&
                (await deleteWhitelistSandfliesOnHost({
                  ids: selection,
                  host: response.host_id
                }))
            }
          }
        ]
      }}
    />
  )
}

export default HostDetailPageWhitelist
