import React, { useState } from 'react'

export type ValidationRule<T> = {
  readonly [P in keyof T]?: (value: T[P], values: T) => boolean
}

export interface UseForm<T> {
  initialValidationRules?: ValidationRule<T>
  initialValues: T
}
type ValidationErrors<T> = Record<keyof T, boolean>

type ValidationKey<T> = keyof T

export type TUseForm<T = { [key: string]: any }> = {
  values: T
  errors: ValidationErrors<T>
  validate: () => boolean
  reset: () => void
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors<T>>>
  setValues: React.Dispatch<React.SetStateAction<T>>
  setFieldValue: <K extends keyof T, U extends T[K]>(field: K, value: U) => void
  setFieldError: (field: keyof T, error: boolean) => void
  validateField: (field: keyof T) => void
  resetErrors: () => void
  onSubmit: (
    handleSubmit: (values: T) => any
  ) => (event?: React.FormEvent<Element> | undefined) => void
  setValidationRules: React.Dispatch<React.SetStateAction<ValidationRule<T>>>
  validateFields: (fields: (keyof T)[]) => boolean
}

export default function useForm<T extends { [key: string]: any }>({
  initialValues,
  initialValidationRules = {}
}: UseForm<T>) {
  const initialErrors = Object.keys(initialValues).reduce((acc, field) => {
    acc[field as keyof T] = false
    return acc
  }, {} as ValidationErrors<T>)

  const [errors, setErrors] = useState(initialErrors)
  const [values, setValues] = useState(initialValues)
  const [validationRules, setValidationRules] = useState(initialValidationRules)

  const resetErrors = () => setErrors(initialErrors)

  const reset = () => {
    setValues(initialValues)
    resetErrors()
  }

  const validate = () => {
    let isValid = true

    const validationErrors = Object.keys(values).reduce((acc, field) => {
      if (typeof validationRules[field] !== 'function')
        acc[field as keyof T] = true

      const rule = validationRules[field]

      if (rule && !rule(values[field], values)) {
        acc[field as keyof T] = true
        isValid = false
      } else {
        acc[field as keyof T] = false
      }

      return acc
    }, {} as ValidationErrors<T>)

    setErrors(validationErrors)
    return isValid
  }

  const validateField = (field: ValidationKey<T>) => {
    const rule = validationRules[field]

    const hasError =
      typeof rule === 'function' ? !rule(values[field], values) : false

    return setErrors((currentErrors) => ({
      ...currentErrors,
      [field]: hasError
    }))
  }

  const validateFields = (fields: ValidationKey<T>[]) => {
    const fieldsWithErrors = {} as ValidationErrors<T>

    fields.forEach((field) => {
      const rule = validationRules[field]

      if (typeof rule === 'function') {
        const result = rule(values[field], values)

        fieldsWithErrors[field] = !result
      } else {
        fieldsWithErrors[field] = false
      }
    })

    setErrors((currentErrors) => ({
      ...currentErrors,
      ...fieldsWithErrors
    }))

    return !fields.find((field) => fieldsWithErrors[field])
  }

  const setFieldError = (field: keyof T, error: boolean) =>
    setErrors((currentErrors) => ({ ...currentErrors, [field]: error }))

  const setFieldValue = <K extends keyof T, U extends T[K]>(
    field: K,
    value: U
  ) => {
    setValues((currentValues) => ({ ...currentValues, [field]: value }))
    setFieldError(field, false)
  }

  const onSubmit =
    (handleSubmit: (values: T) => any) => (event?: React.FormEvent) => {
      event && event.preventDefault()
      validate() && handleSubmit(values)
    }

  return {
    values,
    errors,
    validate,
    reset,
    setErrors,
    setValues,
    setFieldValue,
    setFieldError,
    validateField,
    resetErrors,
    onSubmit,
    setValidationRules,
    validateFields
  }
}
