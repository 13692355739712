import React, { FC } from 'react'
import useApi from '@hooks/useApi'
import { Code, Tab, Tabs, TabPanel, a11yProps } from '@components'
import GenericPage, { GenericPageProps } from './Page'
import { useLocation } from '@reach/router'

export type GenericDetailPageProps<Type = any> = GenericPageProps & {
  api?: ReturnType<typeof useApi>
  tabNames?: string[]
  tabContents?: (
    setCurrentTab: React.Dispatch<React.SetStateAction<number>>
  ) => any[]
}

const GenericDetailPage: FC<GenericDetailPageProps> = ({
  api,
  children,
  tabNames,
  tabContents,
  ...pageProps
}) => {
  const location = useLocation()
  const state = location.state as { tab?: string }

  const initialIndex =
    state?.tab && tabNames && tabNames.indexOf(state.tab) > -1
      ? tabNames.indexOf(state.tab)
      : 0
  const [currentTab, setCurrentTab] = React.useState(initialIndex)

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue)
  }

  const hasTabs = tabNames && tabNames.length > 0

  const tabs = tabContents ? tabContents(setCurrentTab) : []

  return (
    <GenericPage {...pageProps}>
      {hasTabs && (
        <>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="User Account"
            indicatorColor="primary"
          >
            {tabNames?.map((name, index) => (
              <Tab
                key={`${index}-${name}`}
                label={name}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {tabs.map((content, index) => (
            <TabPanel key={`tab-${index}`} value={currentTab} index={index}>
              {content}
            </TabPanel>
          ))}
        </>
      )}

      {children}
      {!children && !tabContents && api && (
        <>
          {api.loading && <pre>loading...</pre>}
          {api.error && <pre>{JSON.stringify(api.error, null, 2)}</pre>}
          {api.response && (
            <Code value={JSON.stringify(api.response, null, 2)} />
          )}
        </>
      )}
    </GenericPage>
  )
}

export default GenericDetailPage
