import React, { FC } from 'react'
import { HostResponse } from '@utils/api'
import { TUseApiHook } from '@hooks/useApi'
import { Chip, Grid, Stack } from '@mui/material'
import { Card, KeyValueTable, Button, HeroStat } from '@components'
import { CloudOffTwoTone, CloudTwoTone, Dns, VpnKey } from '@mui/icons-material'
import { getLinuxDistIcon } from '@utils/icons'
import { Skeleton } from '@mui/material'
import { getStatusPropsFromResponse } from '@utils/hosts'
import { navigate } from 'gatsby'
import { GridLinkOperator } from '@mui/x-data-grid-pro'
import moment from 'moment'

type THostDetailSummary = {
  api: TUseApiHook<HostResponse>
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>
  host_id: string
}

const HostDetailSummary: FC<THostDetailSummary> = ({ api, setCurrentTab }) => {
  const { loading, response } = api
  const {
    isActive,
    hasResults,
    hasAlerts,
    hasErrors,
    hasPasses,
    isOffline,
    isUnknown
  } = getStatusPropsFromResponse(response)

  const prettyName =
    isActive && response ? response.os.info.os_release.pretty_name : ''
  const distribution = prettyName.split(' ')[0]
  const isAuthenticated = response?.authentication_status == 'ok' || false

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item sm>
        <HeroStat
          loading={!response}
          heading="Alerts"
          disabled={isUnknown}
          headingButton={
            <Button
              disabled={!hasAlerts}
              variant="outlined"
              size="small"
              color="error"
              onClick={() =>
                navigate('/results/all/', {
                  state: {
                    requestParams: {
                      filter: {
                        items: [
                          {
                            columnField: 'header.node_name',
                            operatorValue: 'equals',
                            value: response?.os.info.node,
                            id: 1
                          },
                          {
                            columnField: 'data.status',
                            operatorValue: 'equals',
                            value: 'alert'
                          }
                        ],
                        linkOperator: 'and' as GridLinkOperator
                      }
                    }
                  }
                })
              }
            >
              View
            </Button>
          }
          value={response?.results.alert || 0}
          unit={` Alerts`}
          isGood={(!hasAlerts && !isUnknown && hasResults) || false}
          isBad={hasAlerts}
          description="Alerts detected by Sandfly."
        ></HeroStat>
      </Grid>
      <Grid item sm>
        <HeroStat
          loading={!response}
          heading="Errors"
          disabled={isUnknown}
          headingButton={
            <Button
              disabled={!hasErrors}
              variant="outlined"
              color="warning"
              size="small"
              onClick={() =>
                navigate('/results/all/', {
                  state: {
                    requestParams: {
                      filter: {
                        items: [
                          {
                            columnField: 'header.node_name',
                            operatorValue: 'equals',
                            value: response?.os.info.node,
                            id: 1
                          },
                          {
                            columnField: 'data.status',
                            operatorValue: 'equals',
                            value: 'error'
                          }
                        ],
                        linkOperator: 'and' as GridLinkOperator
                      }
                    }
                  }
                })
              }
            >
              View
            </Button>
          }
          value={response?.results.error || 0}
          unit={` Errors`}
          description="Errors whilst scanning."
          isGood={!hasErrors && !isUnknown && hasResults}
          isOfConcern={hasErrors}
        ></HeroStat>
      </Grid>
      <Grid item sm>
        <HeroStat
          loading={!response}
          heading="Passes"
          disabled={isUnknown}
          headingButton={
            <Button
              disabled={!hasPasses}
              variant="outlined"
              size="small"
              onClick={() =>
                navigate('/results/all/', {
                  state: {
                    requestParams: {
                      filter: {
                        items: [
                          {
                            columnField: 'data.status',
                            operatorValue: 'equals',
                            value: 'pass'
                          }
                        ],
                        linkOperator: 'and' as GridLinkOperator
                      }
                    }
                  }
                })
              }
            >
              View
            </Button>
          }
          value={response?.results.pass || 0}
          unit={` Passes`}
          description="Sandflies passed."
          isGood={(hasPasses && !isUnknown) || false}
        ></HeroStat>
      </Grid>
      <Grid item sm={4}>
        <HeroStat
          loading={!response}
          heading="Last Seen"
          value={
            !isUnknown ? moment(response?.date_last_seen).fromNow() : 'Never'
          }
          unit=""
          isGood={!isOffline}
          isOfConcern={isOffline}
          description="Last Successful Scan."
        ></HeroStat>
      </Grid>

      <Grid item sm={7}>
        <Card
          heading="Host Details"
          sx={{ mb: 3 }}
          disabled={isUnknown}
          headingButton={
            response ? (
              <Stack direction="row" spacing={1}>
                <Chip
                  color={isActive ? 'success' : 'warning'}
                  variant="outlined"
                  label={isActive ? 'Active' : 'In-active'}
                  icon={<Dns fontSize="small" />}
                />

                <Chip
                  variant="outlined"
                  color={isAuthenticated ? 'success' : 'warning'}
                  label={isAuthenticated ? 'Authenticated' : 'Un-Authenticated'}
                  icon={<VpnKey fontSize="small" />}
                />
                <Chip
                  color={isOffline ? 'warning' : 'success'}
                  variant="outlined"
                  label={isOffline ? 'Offline' : 'Online'}
                  icon={
                    isOffline ? (
                      <CloudOffTwoTone fontSize="small" />
                    ) : (
                      <CloudTwoTone fontSize="small" />
                    )
                  }
                />
              </Stack>
            ) : (
              <Skeleton height={32} />
            )
          }
        >
          <KeyValueTable
            variant="label-over"
            loading={loading}
            dataPoints={[
              [
                'Distribution',

                distribution ? (
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Grid item>{getLinuxDistIcon(distribution)}</Grid>
                    <Grid item>{prettyName}</Grid>
                  </Grid>
                ) : (
                  'Unknown'
                )
              ],
              ['Platform', response?.os?.info.platform || 'Unknown'],
              ['Release', response?.os?.info.release || 'Unknown'],
              ['CPU Model', response?.os?.hardware.cpu.model_name || 'Unknown'],
              ['Arch', response?.os?.info.arch || 'Unknown'],

              ['Target Address', `${response?.hostname}`],
              ['IP Address', `${response?.first_seen_ip_addr || 'Unknown'}`],
              [
                'Tags',
                response ? response.tags.join(', ') : '',
                <Button
                  size="small"
                  color="inherit"
                  variant="outlined"
                  onClick={() => setCurrentTab(3)}
                >
                  Edit
                </Button>
              ]
            ]}
          />
        </Card>
      </Grid>
      <Grid item sm={5}>
        <Card
          heading="Operational Status"
          sx={{ mb: 3 }}

          // color={getOperationStatus(isActive, isAuthenticated, loading)}
        >
          <KeyValueTable
            variant="label-over"
            loading={loading}
            dataPoints={[
              ['Uptime', `${response?.os?.info.uptime_days || 0} day(s)`],
              [
                'Last Seen',
                !isUnknown
                  ? `${response?.date_last_seen} (${moment(
                      response?.date_last_seen
                    ).fromNow()})`
                  : 'Never'
              ],
              [
                'First Seen',
                !isUnknown
                  ? `${response?.date_first_seen} (${moment(
                      response?.date_first_seen
                    ).fromNow()})`
                  : 'Never'
              ],
              ['Queue Name', `${response?.queue_name}`],
              [
                'Credential',
                `${response?.credentials_id}`,

                <Button
                  size="small"
                  color={'inherit'}
                  variant="outlined"
                  onClick={() => setCurrentTab(3)}
                >
                  Edit
                </Button>
              ],
              [
                'Authentication Status',
                <code>{response?.authentication_status || ''}</code>
              ],
              [
                'Jumphosts',
                `${response?.jump_hosts.join(', ')}`,
                <Button
                  size="small"
                  color="inherit"
                  variant="outlined"
                  onClick={() => setCurrentTab(3)}
                >
                  Edit
                </Button>
              ]
            ]}
          />
        </Card>
      </Grid>
    </Grid>
  )
}

export default HostDetailSummary
