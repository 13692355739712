import React from 'react'
import { useForm } from '@mantine/hooks'
import { Autocomplete, Chip, TextField } from '@mui/material'

export type TTextFieldAutocompleteProps = {
  required: boolean
  id: string
  label?: string
  form: ReturnType<typeof useForm>
  options: string[]
  helperText?: string
  freeSolo?: boolean
  disabled?: boolean
  placeholder?: string
}

export default function TextFieldAutocomplete({
  required = false,
  helperText,
  options,
  freeSolo,
  disabled = false,
  ...props
}: TTextFieldAutocompleteProps) {
  let { id, label, form, ...other } = props

  if (!label) {
    label = id.charAt(0).toUpperCase() + id.slice(1)
  }

  const [inputValue, setInputValue] = React.useState('')

  const onChangeHandler: any = (_event: any, newValue: string | null) => {
    form.setFieldValue(id, newValue as string | null)
  }

  return (
    <Autocomplete
      multiple
      options={options}
      value={form.values[id]}
      onChange={onChangeHandler}
      inputValue={inputValue}
      autoSelect={true}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      disabled={disabled}
      freeSolo={freeSolo}
      renderTags={(value: unknown[], getTagProps) =>
        value.map((option: unknown, index: number) => (
          <Chip
            variant="outlined"
            label={option as string}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true
          }}
          helperText={helperText}
          variant="outlined"
          label={label}
          error={form.errors[props.id]}
          {...other}
        />
      )}
      {...props}
    />
  )
}
