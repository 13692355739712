import React, { FC, MouseEventHandler } from 'react'
import { getHost, deleteHost } from '@utils/api'
import { navigate, PageProps } from 'gatsby'
import useApi from '@hooks/useApi'
import GenericDetailPage from '@templates/Generic/Detail'
import { ButtonGroup } from '@mui/material'
import { Code, Surface, WithCaution, Button } from '@components'
import { Delete, Refresh } from '@mui/icons-material'
import HostDetailSummary from './HostDetailSummary'
import HostDetailPageWhitelist from './HostDetailWhitelist'
import HostDetailSettings from './HostDetailSettings'

const HostDetailPage: FC<PageProps> = ({ params }) => {
  const api = useApi({
    apiMethod: getHost,
    params: params.id
  })

  const { response } = api
  let hostname = ''

  try {
    if (response) {
      hostname = response?.os?.info?.node || 'Host Unknown'
    }
  } catch (error) {
    console.info('Could not retrieve host OS info node.')
  }

  const targetAddress: string = response ? response.hostname : ''

  const handleDelete = async () => {
    api.enqueueSnackbar('Deleting host', { variant: 'info' })
    await deleteHost(params.id)
      .then(() => {
        api.enqueueSnackbar('Host successfully deleted', {
          variant: 'success'
        })
        navigate('/hosts/')
      })
      .catch(() => {
        api.enqueueSnackbar('Host delete failed', { variant: 'success' })
      })
  }

  const handleRefresh: MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement
  > = () => api.refresh(params.id)

  return (
    <GenericDetailPage
      title={hostname || 'Host'}
      metaTitle={`${hostname} | Host`}
      subtitle={targetAddress ? `Target Address: ${targetAddress}` : ' '}
      api={api}
      tabNames={['Summary', 'Whitelist', 'Raw Host Data', 'Settings']}
      maxWidth="lg"
      breadcrumbs={[
        {
          title: 'Hosts',
          to: '/hosts/'
        },
        {
          title: hostname
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button role="button" endIcon={<Refresh />} onClick={handleRefresh}>
            Refresh
          </Button>
          <WithCaution key="caution-delete">
            <Button onClick={() => handleDelete()} endIcon={<Delete />}>
              Delete
            </Button>
          </WithCaution>
        </ButtonGroup>
      }
      tabContents={(setCurrentTab) => [
        <HostDetailSummary
          api={api}
          setCurrentTab={setCurrentTab}
          host_id={params.id}
        />,
        <HostDetailPageWhitelist api={api} host_id={params.id} />,

        <Surface>
          {response && (
            <Code height="auto" value={JSON.stringify(response, null, 2)} />
          )}
        </Surface>,
        <HostDetailSettings
          api={api}
          setCurrentTab={setCurrentTab}
          host_id={params.id}
        />
      ]}
    ></GenericDetailPage>
  )
}

export default HostDetailPage
